<template>
    <div>

        <div class="card-toolbar mb-5 d-flex justify-content-between">
      <div>
            <!--            <router-link v-if="$can('banks.create')" to="/finances/treasury-checking/create" class="btn btn-primary font-weight-bolder">-->
            <!--                <v-icon>mdi-plus</v-icon>-->
            <!--                {{ $t('treasury-checking.add_treasury-checking')}}-->
            <!--            </router-link>-->
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
          </div>
      <div>
        <router-link v-if="$can('treasury_checking.treasury_checking_history')" to="/finances/treasury-checking-history" class="btn btn-primary font-weight-bolder h-100">
          {{ $t('MENU.treasury_checking_history') }}
        </router-link>
      </div>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row mb-5">

                        <div class="col-lg-6 mb-5">
                            <label>{{$t('treasury_checking.treasury')}}</label>
                            <multiselect v-model="treasury"
                                         :placeholder="$t('treasury_checking.treasury')"
                                         label="name"
                                         track-by="id"
                                         :options="treasuries"
                                         :multiple="false"
                                         :taggable="false"
                                         :show-labels="false"
                                         :show-no-options="false"
                                         :show-no-results="false">
                            </multiselect>
                        </div>

                        <div class="form-group col-md-6 mb-5">
                            <label for="from_date">{{$t('treasury_checking.from_date')}}</label>
                            <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mb-5">
                            <label for="to_date">{{$t('treasury_checking.to_date')}}</label>
                            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="last_validated_date">{{$t('treasury_checking.last_validated_date')}}</label>
                            <input v-model="filters.last_validated_date" type="date" id="last_validated_date" class="form-control">
                        </div>

                        <div class="form-group col-md-6 mb-5">
                            <label>{{$t('treasury_checking.currency')}}</label>
                            <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                                <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-6 mb-5">
                            <label>{{$t('treasury_checking.validator_user')}}</label>
                            <multiselect v-model="user"
                                         :placeholder="$t('treasury_checking.validator_user')"
                                         label="name"
                                         track-by="id"
                                         :options="users"
                                         :multiple="false"
                                         :taggable="false"
                                         :show-labels="false"
                                         :show-no-options="false"
                                         :show-no-results="false"
                                         @search-change="getUsers($event)">
                            </multiselect>
                        </div>

                        <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
                            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{$t('search')}}</span>
                            </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{$t('reset_search')}}</span>
                            </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-body">

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="actual_amount" slot-scope="props">
                        <span v-if="!props.row.last_validated_date">
                            {{props.row.actual_amount}}
                        </span>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <template v-if="props.row.last_validated_date || !props.row.treasury_validation_id">
                            <v-btn icon color="pink" v-if="$can('treasury_checking.update')" @click="goToEdit(props.row.treasury_id,props.row.currency_id)">
                                <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('create')">mdi-file</v-icon>
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-btn icon color="pink" v-if="$can('treasury_checking.update')" :to="'/finances/treasury-checking/edit/'+props.row.treasury_validation_id">
                                <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')">mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <template v-if="!props.row.is_confirmed">
                            <b-dropdown v-if="props.row.treasury_validation_id && !props.row.last_validated_date" :id="'dropdown-offset_'+props.row.treasury_validation_id" :text="$t('more_actions') " variant="outline-primary" class="m-2">
                                <a class="dropdown-item" v-if="$can('treasury_checking.approve') && !props.row.last_validated_date" href="javascript:;" @click="makeApprove(props.row.treasury_validation_id)">
                                    {{ $t('approve') }}
                                </a>
                            </b-dropdown>
                            <b-dropdown v-if="props.row.last_validated_date && props.row.current_amount != props.row.last_value" :id="'dropdown-offset_'+props.row.treasury_validation_id" :text="$t('more_actions') " variant="outline-primary" class="m-2">

                                <a class="dropdown-item" v-if="$can('treasury_checking.increase') && props.row.last_validated_date && (props.row.current_amount < props.row.last_value)" href="javascript:;" @click="actionIncreaseDecrease(props.row.treasury_validation_id, 1, (props.row.last_value - props.row.current_amount))">
                                    {{ $t('increase') }}
                                </a>
                                <a class="dropdown-item" v-if="$can('treasury_checking.decrease') && props.row.last_validated_date && (props.row.current_amount > props.row.last_value)" href="javascript:;" @click="actionIncreaseDecrease(props.row.treasury_validation_id, 2, (props.row.current_amount - props.row.last_value))">
                                    {{ $t('decrease') }}
                                </a>
                            </b-dropdown>
                        </template>

                    </template>
                </v-server-table>

            </div>
        </div>
        <!--end::customer-->
        <b-modal ref="increase-decrease" size="md" hide-footer :title="$t(this.is_increase_decrease == 1?'increase':'decrease')">
            <div>
                <div class="form-group row">
                    <div class="col-lg-12 mb-5">
                        <label for="amount">{{$t('amount')}}</label>
                        <input name="amount" id="amount" type="number" min="0" v-model="amount" class="form-control" :class="validation && validation.amount ? 'is-invalid' : ''">
                        <span v-if="validation && validation.amount" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.amount[0] }}
                        </span>
                    </div>
                </div>
                <div class="card-footer pl-0 pr-0 pb-0">
                    <div class="row">
                        <div class="col-lg-6">
                            <button type="reset" class="btn btn-primary mr-2" @click="saveIncreaseDecrease">{{ $t('save') }}</button>
                            <button type="reset" class="btn btn-secondary" @click="hideModal">{{ $t('cancel') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-treasury-checking",
        data() {
            return {
                mainRoute: 'finances/treasury-checking',
                subMainRoute: 'finances/treasury-check',
                mainRouteDependency: 'base/dependency',

                showAdvancedSearch: false,

                filter: {
                    sortBy: 'id',
                },
                filters: {
                    treasury_id: this.$route.query.treasury_id ? this.$route.query.treasury_id : null,
                    currency_id: null,
                    from_date: null,
                    to_date: null,
                    last_validated_date: null,
                    validator_user: null,
                },
                // status_list: [],
                columns: ['treasury_name', 'current_amount', 'currency_name', 'balance_last_validated_date',  'actual_amount', 'actions'],
                data: [],
                treasury: null,
                user: null,
                treasuries: [],
                currencies: [],
                users: [],

                amount: null,
                is_increase_decrease: null, // 1 => increase, 2 => decrease
                treasury_validated_id: null,
                validation: null,

            }
        },
        watch: {
            treasury: function (val) {
                if (val) {
                    this.filters.treasury_id = val.id;
                } else {
                    this.filters.treasury_id = null;
                }
            },
            user: function (val) {
                if (val) {
                    this.filters.validator_user = val.id;
                } else {
                    this.filters.validator_user = null;
                }
            },
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        treasury_name: that.$t('treasury_checking.treasury'),
                        current_amount: that.$t('treasury_checking.current_amount'),
                        currency_name: that.$t('treasury_checking.currency_name'),
                        balance_last_validated_date: that.$t('treasury_checking.last_validate_date'),
                        last_value: that.$t('treasury_checking.last_val'),
                        actual_amount: that.$t('treasury_checking.actual_amount'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params});

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.treasury_checking")}]);
            this.getCurrencies();
            this.getTreasuries();
        },
        methods: {
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.treasury_id = null;
                this.filters.currency_id = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.last_validated_date = null;
                this.filters.validator_user = null;
                this.user = null;
                this.treasury = null;

                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },
            getCurrencies() {
                ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
                    this.currencies = response.data.data;
                });
            },
            getTreasuries() {
                ApiService.get(this.mainRouteDependency + "/treasuries").then((response) => {
                    this.treasuries = response.data.data;
                    if (this.filters.treasury_id) {
                        this.treasury = response.data.data.find((row) => row.id == this.filters.treasury_id);
                    }
                });
            },
            getUsers(filter) {
                if(filter && filter.length >= 3)
                    ApiService.get(this.mainRouteDependency + "/users",{params:{filter: filter}}).then((response) => {
                        this.users = response.data.data;
                    });
            },
            goToEdit(treasury_id, currency_id) {
                this.$router.push({
                    name: 'treasury-checking.edit', query: {
                        treasury_id: treasury_id,
                        currency_id: currency_id,
                    }
                });
            },
            makeApprove(treasury_validation_id) {
                this.$confirmAlert(this.$t('do_you_want_make_it_approved'), this.actionApprove, treasury_validation_id);
            },

            actionApprove(treasury_validation_id) {
                ApiService.patch(`${this.subMainRoute}/approved/${treasury_validation_id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            actionIncreaseDecrease(treasury_validated_id, is_increase_decrease, amount) {
                this.amount = amount;
                this.treasury_validated_id = treasury_validated_id;
                this.is_increase_decrease = is_increase_decrease;
                this.showModal();
            },
            showModal() {
                this.$refs['increase-decrease'].show();
            },
            hideModal() {
                this.$refs['increase-decrease'].hide();
                this.amount = null;
                this.treasury_validated_id = null;
                this.is_increase_decrease = null;
            },
            saveIncreaseDecrease() {
                ApiService.patch(`${this.subMainRoute}/update_amount/${this.treasury_validated_id}`, {
                    amount: this.amount,
                    is_increase_decrease: this.is_increase_decrease,
                }).then((response) => {
                    this.getFetch();
                    this.hideModal();
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
        },
    };
</script>
